<app-user-info-view
  *ngIf="mode === 'view'"
  [organisationId]="organisationId"
  [data]="data$ | async"
  [committeesList]="committeesList$ | async"
  [allowEditing]="allowEditing$ | async"
  [allowDeleting]="allowDeleting ?? (allowDeleting$ | async)"
  [allowSpecialFieldsViewing]="allowSpecialFieldsViewing$ | async"
  [allowSpecialFieldsForAssociationViewing]="allowSpecialFieldsForAssociationViewing$ | async"
  [allowEmailFieldViewing]="allowEmailFieldViewing$ | async"
  (startEditing)="onStartEditing()"
  (deleted)="onDelete($event)"
  [externalLoading]="loading"
></app-user-info-view>

<app-user-info-edit
  *ngIf="mode !== 'view'"
  [mode]="mode"
  [organisationId]="organisationId"
  [isParentOrganisation]="isParentOrganisation"
  [data]="data$ | async"
  [contactsForUnion]="contactsForUnion$ | async"
  [allowIsDefaultOrganisationEditing]="allowIsDefaultOrganisationEditing$ | async"
  [allowSpecialFieldsEditing]="allowSpecialFieldsEditing$ | async"
  [allowSpecialFieldsForAssociationEditing]="allowSpecialFieldsForAssociationEditing$ | async"
  [allowJobTitleFieldsEditing]="allowJobTitleFieldsEditing$ | async"
  [allowJobTitlesForAdminOnlyEditing]="allowJobTitlesForAdminOnlyEditing$ | async"
  [allowAssociationEmployeeEditing]="allowAssociationEmployeeEditing$ | async"
  [allowOrganisationEmployeeEditing]="allowOrganisationEmployeeEditing$ | async"
  [allowEmailFieldEditing]="allowEmailFieldEditing$ | async"
  [allowContactFieldCreating]="allowContactFieldCreating$ | async"
  [allowContactFieldEditing]="allowContactFieldEditing$ | async"
  [allowContactFieldDeleting]="allowContactFieldDeleting$ | async"
  (saved)="onSaveData($event)"
  (canceled)="onCancel()"
  (onPhoneChange)="onChangePhone($event)"
  (deleteAuthUser)="onDeleteAuthUser($event)"
></app-user-info-edit>
