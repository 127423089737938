export const DEEP_LINKS: Record<string, string> = {
  'test-unions.techsrv.ru': 'android-app://me.unions.dev/https/test-unions.techsrv.ru',
  'lk.unions.me': 'android-app://me.unions.app/https/lk.unions.me',
  'deloros.unions.me': 'android-app://me.unions.deloros.app/https/deloros.unions.me',
  'star.unions.me': 'android-app://me.unions.star.app/https/star.unions.me',
  'the8.unions.me': 'android-app://me.unions.the8/https/the8.unions.me',
};

export const GOOGLE_SITE_VERIFICATION: Record<string, string> = {
  'test-unions.techsrv.ru': 'prmOWQYiA0IryPW-VblK5rqFXXDreCI7eRMcDYava00',
  'lk.unions.me': 'McXuXbFnEOb1qN9WItMs_SOZNe6JQpfOqpjFMBeKjrQ',
  'deloros.unions.me': 'McXuXbFnEOb1qN9WItMs_SOZNe6JQpfOqpjFMBeKjrQ',
  'star.unions.me': 'McXuXbFnEOb1qN9WItMs_SOZNe6JQpfOqpjFMBeKjrQ',
  'the8.unions.me': 'McXuXbFnEOb1qN9WItMs_SOZNe6JQpfOqpjFMBeKjrQ',
};
