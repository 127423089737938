import qrcode from 'qrcode';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TUI_NUMBER_FORMAT } from '@taiga-ui/core';
import { EnvService } from '@src/app/modules/env';
import { TokenModel } from '@src/api';
import { AuthService, AuthUserService, StorageService } from '@src/app/modules/auth';
import { AlertService, BreakpointObserverHelperService } from '@src/core/services';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { ScreenTypes } from '@src/models';
import { TelegramAuthService } from '@src/app/modules/telegram';
import { inputPhoneValidator } from '@src/app/modules/phone';
import { TranslateService } from '@ngx-translate/core';
import { StorageKeys } from '@src/constants/storage';
import { EnterType } from '@src/app/modules/login/login.model';

import { QRCODE_IMAGE_WIDTH } from './constants';
import { LoginStep } from './types';

@Component({
  selector: 'telegram-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: TUI_NUMBER_FORMAT,
      useValue: { thousandSeparator: '' },
    },
  ],
})
export class LoginComponent implements OnInit, OnDestroy {
  /** Флаг отображения лого */
  @Input() showLogo = true;

  /** Флаг отображения текста соглашения */
  @Input() showTermOfThePrivacy = true;

  /** Наименование заголовка */
  @Input() title?: string = 'Добро пожаловать';

  /** id ассоциации, в которую логинимся. если не указать, бэк выберет сам в какую */
  @Input() associationId?: string;

  @Input() isBrand?: boolean;

  @ViewChild('qrCodeCanvas') public qrCodeCanvas?: ElementRef;

  /** Текущий шаг логина */
  step: LoginStep = 'loading';

  /** Предыдущий шаг */
  prevStep: LoginStep | null = null;

  /** Флаг выполнения операции */
  loading = false;

  @Output() onLoading: EventEmitter<boolean> = new EventEmitter();

  /** Ссылка зашитая в QR code */
  loginLink?: string;

  /** Номер телефона */
  @Input() phoneNumber = '';
  @Output() phoneNumberChange: EventEmitter<string> = new EventEmitter();

  /** Флаг блокировки ввода номера телефона */
  disabledPhoneNumberInput = false;

  /** Флаг, что телефон не прошел формат */
  phoneInvalid = false;

  /** Флаг, что уже авторизованы */
  alreadyAuthorized = this.authService.isLogged();

  /** Код полученный в телеграм */
  code: string = '';

  /** Флаг, что код не прошел проверку */
  codeInvalid = false;

  /** Пароль в телеграм */
  password?: string;

  /** Флаг, что пароль не прошел проверку */
  passwordInvalid = false;

  /** Флаг для отображения кнопки принудительного завершения сессии (когда она зависла) */
  interruptTelegramSession = false;

  /** Применяем полученный токен */
  @Output() onSuccess: EventEmitter<TokenModel | null> = new EventEmitter();

  private screenType: ScreenTypes = 'extra-large';
  private destroyed$$: Subject<void> = new Subject<void>();

  constructor(
    private breakpointObserver: BreakpointObserver,
    private breakpointObserverHelperService: BreakpointObserverHelperService,
    private cdr: ChangeDetectorRef,
    private env: EnvService,
    readonly telegramAuth: TelegramAuthService,
    private authService: AuthService,
    private authUserService: AuthUserService,
    private readonly alertService: AlertService,
    private readonly translateService: TranslateService,
    private readonly storageService: StorageService,
  ) {
    // Подписка на изменения статуса авторизации Telegram
    this.telegramAuth.authState$.pipe(takeUntil(this.destroyed$$)).subscribe(state => {
      switch (state._) {
        case 'authorizationStateWaitOtherDeviceConfirmation':
          this.setStep('qrcode');
          this.onLoading.emit(false);
          this.loginLink = state.link;
          this.writeQRCode();
          break;

        case 'authorizationStateWaitPhoneNumber':
          this.loginLink = undefined;

          this.setStep('phone');

          if (this.authService.isLogged()) {
            if (!this.authUserService.user?.phone) {
              this.alertService.error(
                this.translateService.instant('components.telegramLogin.alerts.errors.checkPhoneNumber'),
              );
            }

            this.phoneNumber = this.authUserService.user?.phone ?? '';
            this.disabledPhoneNumberInput = this.alreadyAuthorized;
          }

          this.onLoading.emit(false);

          break;

        case 'authorizationStateWaitCode':
          this.setStep('phone');
          this.phoneNumber = state.codeInfo.phoneNumber;

          this.setStep('code');
          this.onLoading.emit(false);
          break;

        case 'authorizationStateWaitPassword':
          this.setStep('password');
          this.onLoading.emit(false);
          break;

        case 'authorizationStateReady':
          this.setStep('loading');
          break;

        case 'authorizationStateLoggingOut':
          this.interruptTelegramSession = true;
          this.cdr.markForCheck();
          break;

        case 'authorizationStateClosed':
          if (this.prevStep === 'qrcode' || this.prevStep === 'code') {
            this.prevStep = null;
            this.setLoading(false);
            this.telegramAuth.authenticate();
          }

          if (this.prevStep) {
            this.onLoading.emit(false);
            this.setStep('phone');
          }

          break;

        default:
          break;
      }

      this.cdr.markForCheck();
    });

    // Подписка на получение токена
    this.telegramAuth.success$.pipe(takeUntil(this.destroyed$$)).subscribe(result => {
      this.onSuccess.emit(result ? { token: result } : null);
    });

    // Подписка на получение статуса основного бота
    this.telegramAuth.checkMainBotStatus$.pipe(takeUntil(this.destroyed$$)).subscribe(status => {
      if (status) {
        const step: LoginStep = status === 'timeout' ? 'unlock-bot-timeout' : 'unlock-bot';
        if (step !== this.prevStep) {
          this.setStep(step);
          this.cdr.markForCheck();
        }
      }
    });
  }

  async ngOnInit(): Promise<void> {
    this.storageService.setItem(StorageKeys.EnterType, EnterType.Messenger);

    this.breakpointObserver
      .observe(this.breakpointObserverHelperService.breakpointsSet)
      .pipe(takeUntil(this.destroyed$$))
      .subscribe((state: BreakpointState) => {
        this.screenType = this.breakpointObserverHelperService.getScreenType(state);
        this.cdr.markForCheck();
      });

    this.onLoading.emit(true);
    this.telegramAuth.authenticate();
  }

  private setStep(step: LoginStep) {
    this.prevStep = this.step;
    this.step = step;
  }

  private setLoading(loading: boolean) {
    this.loading = loading;
    this.onLoading.emit(loading);
  }

  ngOnDestroy(): void {
    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  onPhoneNumberChange(phoneNumber: string) {
    this.phoneNumberChange.emit(phoneNumber);
  }

  async gotoPhone() {
    this.code = '';
    this.password = undefined;
    this.prevStep = 'code';
    this.setLoading(true);

    await this.telegramAuth.logout();
    this.cdr.markForCheck();
  }

  sendPhone(): void {
    this.phoneInvalid = !inputPhoneValidator(this.phoneNumber);
    if (this.phoneInvalid) {
      this.cdr.markForCheck();
      return;
    }

    this.setLoading(true);
    this.telegramAuth.setPhoneNumber(this.phoneNumber)?.then(({ response }) => {
      this.setLoading(false);
      if (response._ === 'error') {
        this.phoneInvalid = true;
      } else {
        this.setStep('code');
      }

      this.cdr.markForCheck();
    });
  }

  sendCode(): void {
    if (!this.code) {
      return;
    }

    this.setLoading(true);
    this.telegramAuth.checkCode(this.code)?.then(({ response }) => {
      this.setLoading(false);
      if (response._ === 'error') {
        this.codeInvalid = true;
      }
      this.cdr.markForCheck();
    });
  }

  sendPassword(): void {
    if (!this.password) {
      return;
    }

    this.setLoading(true);
    this.telegramAuth.checkPassword(this.password)?.then(({ response }) => {
      if (response._ === 'error') {
        this.passwordInvalid = true;
        this.setLoading(false);
      }
      this.cdr.markForCheck();
    });
  }

  interruptSession(): void {
    this.telegramAuth.interruptSession();
  }

  onChangePassword(): void {
    this.passwordInvalid = false;
  }

  private writeQRCode(): void {
    setTimeout(() => {
      if (!this.loginLink || !this.qrCodeCanvas) {
        return;
      }

      qrcode.toCanvas(this.qrCodeCanvas.nativeElement, this.loginLink, { width: QRCODE_IMAGE_WIDTH }, () => {
        this.cdr.markForCheck();
      });
    });
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent): void {
    if (event.key === 'Enter' && !this.loading) {
      switch (this.step) {
        case 'phone':
          this.sendPhone();
          break;

        case 'code':
          this.sendCode();
          break;

        case 'password':
          this.sendPassword();
          break;

        default:
          break;
      }
    }
  }
}
